/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Modal, Pagination, Button, Notification, Answer } from '../../components';
import Select from 'react-select';
import {
  fetchParticipants,
  fetchCompanies,
  deleteParticipant,
  sendReminder,
  sendCompanyReminder
} from '../../containers/participants/fetchingFunctions';

import plusIcon from '../../assets/icons/plus.svg';
import noDataIllustration from '../../assets/icons/noData.svg';
import AddParticipant from './AddParticipant';
import AddMultipleParticipants from './AddMultipleParticipants';

const Participants = () => {
  const { isSuperAdmin } = useSelector((state) => state.user);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [openAddParticipantsModal, setOpenAddParticipantsModal] = useState(false);
  const [openAddMultipleParticipantsModal, setOpenAddMultipleParticipantsModal] = useState(false);
  const [searchEmail, setSearchEmail] = useState('');
  const [companies, setCompanies] = useState([]);
  const [participantsPerPage, setPartocipantsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [companyFilter, setCompanyFilter] = useState('');
  const [participantEmailToDelete, setParticipantEmailToDelete] = useState('');
  const [newParticipants, setNewParticipants] = useState([]);
  const [participantsData, setParticipantsData] = useState({
    count: 0,
    currentPage: 0,
    participants: [],
    totalPages: 0
  });

  const companyOptions = [
    { value: '', label: 'Todas' },
    ...companies.map((company) => ({
      value: company._id,
      label: company.name
    }))
  ];

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const toggleDeleteModal = () => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };
  const toggleAddParticipantsModal = () => {
    setOpenAddParticipantsModal(!openAddParticipantsModal);
  };
  const toggleAddMultipleParticipantsModal = () => {
    setOpenAddMultipleParticipantsModal(!openAddMultipleParticipantsModal);
  };

  const setParticipantToDelete = (email) => {
    setParticipantEmailToDelete(email);
    toggleDeleteModal();
  };

  const handleSendRemindersForCompany = async () => {
    sendCompanyReminder(setSuccess, setApiError, setLoading);
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(null);
      }, 2000);
      return () => clearTimeout(timer);
    }

    if (isSuperAdmin) fetchCompanies(setCompanies);
  }, [isSuperAdmin, success]);

  useEffect(() => {
    fetchParticipants(
      searchEmail,
      currentPage,
      participantsPerPage,
      companyFilter,
      setParticipantsData
    );
  }, [
    currentPage,
    searchEmail,
    success,
    companyFilter,
    participantsPerPage
    // toggleAddParticipantsModal
  ]);

  return (
    <>
      <div className="flex w-full flex-col mt-5 md:mt-10 px-8">
        <div className="flex flex-col mb-7 sm:flex-row w-full justify-between">
          <p className="text-xl">Participantes</p>
          {!isSuperAdmin ? (
            <button
              onClick={toggleAddParticipantsModal}
              className="px-6 py-3 rounded-md bg-green_btn w-56 items-center text-white flex">
              <img src={plusIcon} alt="add" draggable={false} className="mr-2" />
              <span className="whitespace-nowrap text-sm">Agregar participantes</span>
            </button>
          ) : null}
        </div>
        <div className="w-full">
          {isSuperAdmin ? (
            <div className=" rounded-lg py-4 mb-3 pr-12 flex flex-col gap-x-4 sm:flex-row justify-left">
              <p className="text-secondary py-2">Selecciona empresa</p>
              {companies.length > 0 ? (
                <Select
                  className="outline-none border-none w-56 marker:outline-none"
                  styles={{
                    input: (base) => ({
                      ...base,
                      'input:focus': {
                        boxShadow: 'none'
                      }
                    })
                  }}
                  defaultValue={companyOptions[0]}
                  isSearchable
                  name="company"
                  options={companyOptions}
                  onChange={(e) => setCompanyFilter(e.value)}
                />
              ) : null}
            </div>
          ) : null}
          <Input
            showCrossIcon
            type="text"
            onIconClick={() => setSearchEmail('')}
            onFocus={() => setApiError(null)}
            placeholder="Buscar por correo electrónico..."
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
          {!isSuperAdmin && (
            <div className="flex flex-col">
              <button
                className="text-secondary w-56 disabled:text-gray-400 text-right mb-4 self-end"
                disabled={participantsData.count === 0 || loading}
                loading={loading}
                onClick={handleSendRemindersForCompany}>
                Enviar Recordatorio Masivo
              </button>
            </div>
          )}

          {success !== null && <Notification type="success" message={success} />}
          {participantsData.participants.length > 0 && !loading ? (
            <>
              <div className="border rounded-lg">
                <table className="table-fixed w-full">
                  <thead className="w-full">
                    <tr
                      className="bg-[#FAFAFA] rounded-t-lg hidden lg:grid lg:grid-cols-4 
                    ">
                      <th className="p-6 text-left  text-sm font-medium">Correo electrónico</th>
                      <th className="p-6  text-left text-sm font-medium border-l border-r">
                        Juego de Respuesta Automática
                      </th>
                      <th className="p-6  text-left text-sm font-medium border-r">
                        Juego de Palabras
                      </th>

                      {isSuperAdmin ? (
                        <th className="p-6 text-left text-sm font-medium ">Visualización</th>
                      ) : (
                        <th
                          className={`p-6 text-left text-sm font-medium ${
                            isSuperAdmin && 'border-r'
                          } `}>
                          Acciones
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {participantsData.participants.length > 0
                      ? participantsData.participants.map((participant, index) => (
                          <tr key={participant.email} className="grid grid-cols-1 lg:grid-cols-4">
                            <td
                              className={`p-6 pl-3 truncate ${
                                index === 0 ? 'rounded-t-lg' : ''
                              } lg:rounded-b-lg bg-light_gray lg:bg-white lg:p-6 text-sm`}>
                              {participant.email}
                            </td>
                            <td className="p-3 lg:p-6 text-sm lg:border-l bg-white lg:border-r">
                              <Answer answered={participant.jra} />
                            </td>
                            <td
                              className={`p-3 lg:p-6 text-sm bg-white ${
                                index === participantsData.participants.length - 1
                                  ? 'rounded-b-lg '
                                  : ''
                              } lg:rounded-none lg:border-r`}>
                              <Answer answered={participant.jp} />
                            </td>

                            {!isSuperAdmin ? (
                              <td
                                className={`p-3 lg:p-6 text-sm flex flex-col ${
                                  isSuperAdmin && 'lg:border-r'
                                } `}>
                                <button
                                  className="text-red-500 text-left"
                                  onClick={() => setParticipantToDelete(participant.email)}>
                                  Eliminar usuario
                                </button>
                                <button
                                  disabled={participant.jp || loading}
                                  onClick={() =>
                                    sendReminder(
                                      participant.email,
                                      setLoading,
                                      setSuccess,
                                      setApiError
                                    )
                                  }
                                  className="text-secondary disabled:text-gray-400 text-left">
                                  Reenviar invitación
                                </button>
                              </td>
                            ) : (
                              <td className="hidden lg:flex lg:justify-center lg:items-center">
                                <button
                                  className="px-4 py-2 rounded-lg bg-green_btn text-sm disabled:bg-gray-400 text-white"
                                  disabled={!participant.jp || !participant.jra}>
                                  {participant.jp && participant.jra ? (
                                    <>
                                      <a
                                        href={`${process.env.REACT_APP_GAME_URL}/game/${participant._id}`}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Ver resultados
                                      </a>
                                      {isSuperAdmin && (
                                        <p>
                                          (`${participant.g} - ${participant.a} - ${participant.c}`)
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    'Ver resultados'
                                  )}
                                </button>
                              </td>
                            )}
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
              <Pagination
                itemsPerPage={participantsPerPage}
                totalResults={participantsData.count}
                nextPage={nextPage}
                prevPage={prevPage}
                currentPage={currentPage}
                setResultsPerPage={setPartocipantsPerPage}
              />
            </>
          ) : (
            <div className="flex my-4 flex-col items-center justify-center">
              <img className="h-24 w-24" src={noDataIllustration} alt="no data" draggable={false} />
              <p className="text-xl text-center">No se encontraron participantes</p>
            </div>
          )}
        </div>
      </div>

      <Modal isOpen={isOpenDeleteModal} toggleModal={toggleDeleteModal}>
        <p className="text-secondary text-lg mb-7 font-semibold">Eliminar participante</p>
        <p>
          ¿Estás seguro que quieres eliminar a{' '}
          {participantEmailToDelete ? (
            <span className="text-secondary">{participantEmailToDelete}</span>
          ) : (
            'este usuario'
          )}
          ?
          <br />
          Esta acción no se puede deshacer.
        </p>
        {apiError && <Notification type="error" message={apiError} />}
        <div className="flex flex-col items-center justify-center gap-x-5 sm:flex-row">
          <Button
            mb="mb-2 "
            disabled={loading}
            loading={loading}
            onClick={() =>
              deleteParticipant(
                setLoading,
                participantEmailToDelete,
                setSuccess,
                toggleDeleteModal,
                setApiError
              )
            }
            variant="danger">
            Si, eliminar
          </Button>
          <Button mt="mt-4 sm:mt-8" onClick={toggleDeleteModal}>
            Cancelar
          </Button>
        </div>
      </Modal>

      <Modal isOpen={openAddParticipantsModal} toggleModal={toggleAddParticipantsModal}>
        <AddParticipant
          toggleAddParticipantsModal={toggleAddParticipantsModal}
          toggleAddMultipleParticipantsModal={toggleAddMultipleParticipantsModal}
          newParticipants={newParticipants}
          setNewParticipants={setNewParticipants}
          setSuccess={setSuccess}
        />
      </Modal>

      <Modal
        isOpen={openAddMultipleParticipantsModal}
        toggleModal={toggleAddMultipleParticipantsModal}>
        <AddMultipleParticipants
          newParticipants={newParticipants}
          toggleAddParticipantsModal={toggleAddParticipantsModal}
          toggleAddMultipleParticipantsModal={toggleAddMultipleParticipantsModal}
        />
      </Modal>
    </>
  );
};

export default Participants;
